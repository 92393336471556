import React from 'react'
import './css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
        <footer class="footer-section">

<div class="copyright-option">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <ul>
                
                    <li><Link to='/TermsPage'>Privacy policy</Link></li>
                    <li><Link to='/Admin/Login'><button class="btn btn-light">Admin</button></Link></li>
                </ul>
            </div>
            <div class="col-lg-5">
                <div class="co-text">
                    <p>
                        Copyright &copy;<script>
                            document.write(new Date().getFullYear());
                        </script> All rights reserved | by FABRO ROOMS 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</footer>
    </div>
  )
}

export default Footer