import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const RoomDetails = ({ roomDetails }) => {
  const [details, setDetails] = useState(roomDetails);

  const handleCancelRoom = async (bookingId, index) => {
    try {
      const response = await axios.get(`http://localhost:3001/api/booking/${bookingId}/cancellationStatus`);
      const cancellationStatus = response.data.cancellation;
      
      if (cancellationStatus === 'cancelled') {
        // If already cancelled, show a message
        Swal.fire('Cancelled!', 'This room booking has already been cancelled.', 'info');
      } else {
        // Show confirmation dialog
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this booking!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, cancel it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            // User confirmed cancellation
            const cancelResponse = await axios.put(`http://localhost:3001/api/cancel/${bookingId}/cancel`);
            if (cancelResponse.status === 200) {
              // Booking cancelled successfully
              Swal.fire('Cancelled!', 'Your room booking has been cancelled.', 'success');
              // Update UI by changing row background color and button text
              const updatedDetails = [...details];
              updatedDetails[index].cancellation = 'cancelled';
              setDetails(updatedDetails);
            }
          }
        });
      }
    } catch (error) {
      console.error('Failed to cancel room booking:', error);
      Swal.fire('Error!', 'Failed to cancel room booking. Please try again later.', 'error');
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Your Booking Details</h3>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>S.No</th>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Number</th>
            <th style={styles.th}>Room Type</th>
            <th style={styles.th}>Booking Date</th>
            <th style={styles.th}>Check-in</th>
            <th style={styles.th}>Check-out</th>
            <th style={styles.th}>Total Days</th>
            <th style={styles.th}>Guests</th>
            <th style={styles.th}>Total Price</th>
            <th style={styles.th}>Cancellation</th> {/* Added this column */}
          </tr>
        </thead>
        <tbody>
          {details.map((detail, index) => (
            <tr key={index} style={{ backgroundColor: detail.cancellation === 'cancelled' ? '#ffcccc' : 'inherit' }}>
              <td style={styles.td}>{index + 1}</td>
              <td style={styles.td}>{detail.name}</td>
              <td style={styles.td}>{detail.number}</td>
              <td style={styles.td}>{detail.room_type}</td>
              <td style={styles.td}>{formatDate(detail.booking_date)}</td>
              <td style={styles.td}>{formatDate(detail.check_in)}</td>
              <td style={styles.td}>{formatDate(detail.check_out)}</td>
              <td style={styles.td}>{detail.length_of_stay}</td>
              <td style={styles.td}>{`${detail.adults} Adults, ${detail.children} Children`}</td>
              <td style={styles.td}>Rs.{detail.total_amount}</td>
              <td style={styles.td}>
                {detail.cancellation === 'cancelled' ? (
                  <span style={{ color: 'red' }}>Cancelled</span>
                ) : (
                  <button className='btn btn-danger' onClick={() => handleCancelRoom(detail.id, index)}>
                    {detail.cancellation ? "Cancelled" : "Cancel Room"}
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Function to format date in the specified format
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toDateString();
};

const styles = {
  container: {
    maxWidth: '100%', // Adjust to fit your page width
    overflowX: 'auto', // Enable horizontal scrolling if needed
    marginBottom: '20px', // Add some spacing at the bottom
  },
  heading: {
    marginBottom: '20px',
    marginTop:'15px',
    color: '#333',
    textAlign: 'center', // Center the heading
  },
  table: {
    width: '90%', // Reduce table width to 90% of the container
    margin: '0 auto', // Center the table horizontally
    borderCollapse: 'collapse',
    border: '1px solid #ddd', // Adjust border color
    fontFamily: 'calibri',
    marginBottom: '50px'
  },
  th: {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    borderRight: '1px solid #ddd',
  },
  td: {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    borderRight: '1px solid #ddd',
  },
};

export default RoomDetails;