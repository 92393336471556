import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./css/bootstrap.min.css";
import logo from "./img/logo.png";
import back from "./img/back.png";

export const updateCartCount = () => {
  const selectedRooms = JSON.parse(localStorage.getItem("selectedRooms")) || [];
  const cartCountBadge = document.getElementById("cartCountBadge");

  // Calculate the total number of selected rooms
  const totalRooms = selectedRooms.reduce(
    (total, room) => total + room.roomCount,
    0
  );

  // Update the cart count badge
  if (cartCountBadge) {
    cartCountBadge.textContent = totalRooms.toString();
  }
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    updateCartCount();
  }, []);

  const [activeLink, setActiveLink] = useState("Home"); // Default active link is 'Home'

  // Function to handle link click and update the active link
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1);
  };

  // Update active link when location changes
  useEffect(() => {
    const pathname = location.pathname.substring(1); // Remove leading "/"
    setActiveLink(pathname || "Home"); // If pathname is empty (e.g., on the homepage), set active link to "Home"
  }, [location.pathname]);

  // Constants for styles
  const headerSectionStyle = {
    borderBottom: "1px solid darkgray",
  };

  const iconStyle = {
    fontSize: "24px",
  };

  const cartBadgeStyle = {
    position: "absolute",
    top: "12px",
    // right:'9%',
    color: "crimson",
    padding: "1px 1px",
    fontSize: "15px",
  };

  const backButtonStyle = {
    marginLeft: "10px",
    cursor: "pointer",
    fontWeight: "bold",
    textColor: "black",
    display: location.pathname === "/" ? "none" : "block",
  };

  return (
    <div>
      <header className="header-section" style={headerSectionStyle}>
        <div className="menu-item">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="Description" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-10">
                <div className="nav-menu">
                  <nav className="mainmenu">
                    <ul>
                      <li className={activeLink === "Home" ? "active" : ""}>
                        <Link to="/" onClick={() => handleLinkClick("Home")}>
                          Home
                        </Link>
                      </li>
                      <li className={activeLink === "Rooms" ? "active" : ""}>
                        <Link
                          to="/Rooms"
                          onClick={() => handleLinkClick("Rooms")}
                        >
                          Rooms
                        </Link>
                      </li>
                      <li id="cartIcon" className={activeLink === "Cart" ? "active" : ""}>
                        <Link
                          to="/Cart"
                          onClick={() => handleLinkClick("Cart")}
                        >
                          <span>Cart</span>
                          <i
                            className="fa fa-shopping-cart icon"
                            style={iconStyle}
                          ></i>
                          <span id="cartCountBadge" style={cartBadgeStyle}>
                            0
                          </span>
                        </Link>
                      </li>
                      <li className={activeLink === "Contact" ? "active" : ""}>
                        <Link
                          to="/Contact"
                          onClick={() => handleLinkClick("Contact")}
                        >
                          Contact
                        </Link>
                      </li>
                      <li className={activeLink === "MyBooking" ? "active" : ""}>
                        <Link
                          to="/MyBooking"
                          onClick={() => handleLinkClick("MyBooking")}
                        >
                          My Booking
                        </Link>
                      </li>
                      {window.history.length > 1 && (
                        <li>
                          <span onClick={goBack} style={backButtonStyle}>
                            Back{" "}
                            <img
                              src={back}
                              style={{ width: "20px", height: "20px" }}
                              alt=""
                            />
                          </span>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;